<template>
  <b-row>
    <b-col cols="2">
      <b-list-group>
        <b-list-group-item href="#" v-on:click="changeFocus('general')" :active="focus === 'general'">General</b-list-group-item>
        <b-list-group-item href="#" v-on:click="changeFocus('events')" :active="focus === 'events'">Events</b-list-group-item>
        <b-list-group-item href="#" v-on:click="changeFocus('users')" :active="focus === 'users'">Manage Users</b-list-group-item>
        <b-list-group-item href="#" v-on:click="changeFocus('tags')" :active="focus === 'tags'">Manage Tags</b-list-group-item>
        <b-list-group-item href="#" v-on:click="changeFocus('cost-objects')" :active="focus === 'cost-objects'">Manage Cost Objects</b-list-group-item>
      </b-list-group>
    </b-col>

    <b-col v-show="focus === 'general'">
      <h3>General Settings</h3>

    </b-col>

    <b-col v-show="focus === 'events'">
      <h3>Events</h3>
      <b-form  @submit.stop.prevent>

        <b-form-input v-model="event_filter_category" placeholder="Category"></b-form-input>
        <b-form-input v-model="event_filter_severity" placeholder="Severity"></b-form-input>
        <div class="text-right">
          <b-button squared variant="primary" v-on:click="loadEvents(1)">Apply</b-button>
        </div>
      </b-form>
      <table class="table table-striped table-hover">
        <col style="width:20%">
        <col style="width:10%">
        <col style="width:15%">
        <col style="width:60%">
        <thead>
        <tr>
          <th>Event Time</th>
          <th>Severity</th>
          <th>Category</th>
          <th>message</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="event in events" :key="event.id">
          <td>{{new Date(event.timestamp*1000).toLocaleString()}}</td>
          <td>{{event.severity}}</td>
          <td>{{event.category}}</td>
          <td>{{event.message}}</td>
        </tr>
        </tbody>
      </table>
      <b-pagination
          v-model="event_page"
          :total-rows="n_events"
          :per-page="events_per_page"
          align="center"
          v-show="!event_table_loading"
          v-on:change="loadEvents"
          first-number last-number
      >
      </b-pagination>

    </b-col>

    <b-col v-show="focus === 'users'">
      <h3>Users</h3>


      <table class="table table-striped">
        <tr v-for="user in users" :key="user.id">
          <td> {{user.username}} </td>
          <td> {{user.full_name}} </td>
          <td> {{user.title}} </td>
          <td> &lt;{{user.email}}&gt; </td>
          <td> ({{user.phone}}) </td>
          <td> <span v-if="user.is_admin">Admin</span> </td>
          <td>
            <b-button-group>
              <b-button variant="warning" size="sm" @click="startUserEdit(user)">Edit</b-button>
              <b-button v-if="user.is_active" variant="danger" size="sm" @click="setUserActive(user, false)">Deactivate</b-button>
              <b-button v-else variant="success" size="sm" @click="setUserActive(user, true)">Activate</b-button>
            </b-button-group>
          </td>
        </tr>
      </table>
      <b-button @click="startUserEdit(null)" v-show="!create_user">Register new User</b-button>


      <b-form v-show="create_user">
        <b-card>
        <b-row>
          <b-col cols="3">
            <b-form-group label="Username" >
              <b-form-input
                  v-model="new_user.username"
                  placeholder="username"
                  required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Full Name" >
              <b-form-input
                  v-model="new_user.full_name"
                  placeholder="Full Name"
                  required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Title" >
              <b-form-input
                  v-model="new_user.title"
                  placeholder="Title"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Email Address" >
              <b-form-input
                  v-model="new_user.email"
                  placeholder="your@email.com"
                  required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Phone Number" >
              <b-form-input
                  v-model="new_user.phone"
                  placeholder="(555) 555-5555"
                  required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group
                label="Password"
            >
              <b-form-input
                  v-model="new_user.password"
                  placeholder="password"
                  type="password"
                  required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col v-if="editing_user" :cols="3">
            <b-form-group
                label="Administrator"
            >
              <b-form-checkbox
                  v-model="new_user.is_admin"
              ></b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="editing_user">
          <b-col cols="12">
            <b-card title="Tag Subscriptions" >
              <b-list-group>
                <b-list-group-item v-for="tag in new_user.subscriptions" :key="tag.id">
                  <b-form-checkbox v-model="tag.subscribed" switch @change="subscriptions_changed=true">
                    <Tag :id="tag.id" :description="tag.description" :color="tag.color"></Tag>
                  </b-form-checkbox>
                </b-list-group-item>
              </b-list-group>
            </b-card>

          </b-col>
        </b-row>
        <b-row>
          <b-col :cols="4" align-self="center">
            <b-button-group size="sm">
              <b-button v-if="editing_user"  variant="success" @click="submitUserEdit">Submit Changes</b-button>
              <b-button v-else  variant="success" @click="submitUserCreate">Create User</b-button>
              <b-button variant="warning" @click="create_user=false">Cancel</b-button>
            </b-button-group>
          </b-col>
        </b-row>
        </b-card>
      </b-form>
    </b-col>

    <b-col v-show="focus === 'tags'">
      <h3>Tags</h3>

      <b-list-group >
        <b-list-group-item v-for="tag in tags" :key="tag.id">
          <b-row>


            <b-col :cols="3">
              <Tag :id="tag.id" :color="tag.color"></Tag>
            </b-col>
            <b-col :cols="4">
            </b-col>
            <b-col :cols="3">
              {{tag.description}}
            </b-col>
            <b-col :cols="2">
              <b-button-group>
                <b-button variant="warning" size="sm" @click="startTagEdit(tag)">Edit</b-button>
                <b-button variant="danger" size="sm" @click="deleteTag(tag)">Delete</b-button>
              </b-button-group>
            </b-col>

          </b-row>
        </b-list-group-item>
      </b-list-group>

      <b-button @click="startTagEdit(null)" v-show="!create_tag">Create new tag</b-button>

      <b-form v-show="create_tag">
        <b-row>
          <b-col :cols="3">
            <b-form-group
                label="Tag Name"
            >
              <b-form-input
                  v-model="new_tag.id"
                  placeholder="Tag Name"
                  required
                  :disabled="editing_tag"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col :cols="3">
            <b-form-group
                label="Description"
            >
              <b-form-input
                  v-model="new_tag.description"
                  placeholder="short description (optional)"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col :cols="3">
            <b-form-group
                label="Label Color"
            >
              <div style="display: flex!important">

                <b-button @click="randomColor" :style="'background-color: '+new_tag.color">
                  <b-icon icon="arrow-counterclockwise"></b-icon>
                </b-button>
                <b-form-input
                    v-model="new_tag.color"
                    placeholder="#000000"
                ></b-form-input>

              </div>
            </b-form-group>
          </b-col>
          <b-col :cols="2" align-self="center">
            <b-button-group size="sm">
              <b-button variant="success" @click="createTag">Submit</b-button>
              <b-button variant="warning" @click="create_tag=false">Cancel</b-button>
            </b-button-group>
          </b-col>
        </b-row>
        <b-row>
          Preview: <Tag :id="new_tag.id" :color="new_tag.color"></Tag>
        </b-row>

      </b-form>
    </b-col>

    <b-col v-show="focus === 'cost-objects'">
      <h3>Cost Objects</h3>

      <b-list-group >
        <b-list-group-item v-for="co in cost_objects" :key="co.id">
          <b-row>


            <b-col cols="3">
              {{co.id}}
            </b-col>
            <b-col cols="4">
              {{co.description}}
            </b-col>
            <b-col cols="2">
              {{co.active ? "Active" : "Inactive"}}
            </b-col>
            <b-col cols="2">
              <b-button-group>
                <b-button variant="warning" size="sm" @click="startCostObjectEdit(co)">Edit</b-button>
              </b-button-group>
            </b-col>

          </b-row>
        </b-list-group-item>
      </b-list-group>

      <hr/>

      <b-button @click="startCostObjectEdit(null)" v-show="!editing_cost_object">Create new cost object</b-button>

      <b-form v-show="editing_cost_object">
        <b-row>
          <b-col cols="3">
            <b-form-group label="Cost Object" >
              <b-form-input
                  v-model="new_cost_object.id"
                  placeholder="Cost Object"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Description" >
              <b-form-input
                  v-model="new_cost_object.description"
                  placeholder="Short description"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group label="Is Active?" >
              <b-form-checkbox
                  v-model="new_cost_object.active"
              ></b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col :cols="2" align-self="center">
            <b-button-group size="sm">
              <b-button variant="success" @click="createCostObject">Submit</b-button>
              <b-button variant="warning" @click="editing_cost_object=false">Cancel</b-button>
            </b-button-group>
          </b-col>
        </b-row>
      </b-form>
    </b-col>
  </b-row>

</template>

<script>
import http from "../http-common"
import Tag from "./Tag"
export default {
  name: "SettingsMain",
  components: {
    Tag
  },
  props: [
      'tags',
      'users',
      'cost_objects',
      'loggedIn'
  ],
  data: function (){
    return {
      focus: 'general',

      events: [],
      n_events: 0,
      event_page: 1,
      events_per_page: 10,
      event_table_loading: false,
      event_filter_category: "",
      event_filter_severity: "",

      new_tag: {
        id: '',
        description: '',
        color: '#000000',
      },
      create_tag: false,
      editing_tag: false,

      new_user: {
        original_username: null,
        username: '',
        password: '',
        full_name: '',
        title: '',
        email: '',
        phone: '',
        is_admin: false,
        subscriptions: [],
      },
      create_user: false,
      editing_user: false,

      editing_cost_object: false,
      new_cost_object: {
        original_id: null,
        id: '',
        description: '',
        active: true,
      },
    }
  },
  methods: {
    changeFocus(new_focus){
      this.focus = new_focus;
      if (this.focus === 'users') {
        this.$emit('sync-users');
      } else if (this.focus === 'tags') {
        this.$emit('sync-tags');
      } else if (this.focus === 'events') {
        this.loadEvents(1);
      } else if (this.focus == 'cost-objects') {
        this.$emit('sync-cost-objects');
      }
    },
    loadEvents(page) {
      let url = "/event/";
      url += `?offset=${(page-1)*this.events_per_page}`;
      url += `&limit=${this.events_per_page}`;
      url += `&filter_category=${this.event_filter_category}`;
      url += `&filter_severity=${this.event_filter_severity}`;
      this.event_table_loading = true;
      http.get(url)
      .then((resp) => {
        this.events = resp.data.events;
        this.n_events = resp.data.n_events;
        this.event_table_loading = false;
      })
      .catch((err) => {
        http.std_error_handler(this, err);
        this.event_table_loading = false;
      })
    },
    setUserActive(user, active) {
      if (active) {
        http.put("/user/reactivate/"+encodeURIComponent(user.username))
        .then(() => {
          this.$emit('sync-users');
        })
        .catch((err) => {
          http.std_error_handler(this, err);
        });
      } else {
        http.put("/user/deactivate/"+encodeURIComponent(user.username))
            .then(() => {
              this.$emit('sync-users');
            })
            .catch((err) => {
              http.std_error_handler(this, err);
            });
      }
    },
    startUserEdit(user) {
      if (user === null) {
        this.new_user = {
          original_username: '',
          username: '',
          full_name: '',
          title: '',
          email: '',
          phone: '',
          is_admin: false,
          password: '',
        };
        this.editing_user = false;
      } else {
        this.new_user = {
          original_username: user.username,
          username: user.username,
          full_name: user.full_name,
          title: user.title,
          email: user.email,
          phone: user.phone,
          is_admin: user.is_admin,
          password: '',
          subscriptions: user.subscriptions,
        };
        this.editing_user = true;
      }
      this.create_user = true;
    },
    submitUserCreate() {
      http.put('/user/create',{},{
        params: {
          username: this.new_user.username,
          full_name: this.new_user.full_name,
          title: this.new_user.title,
          email: this.new_user.email,
          phone: this.new_user.phone,
          is_admin: this.new_user.is_admin,
          password: this.new_user.password,
        }
      })
      .then(() => {
        this.$emit('sync-users');
        this.editing_user = false;
        this.create_user = false;
        this.new_user = {
          original_username: null,
          username: '',
          password: '',
          full_name: '',
          title: '',
          email: '',
          phone: '',
          is_admin: false,
        };
      })
      .catch((err) => {
        if(http.std_error_handler(this, err)) {
          this.$emit('show-alert', {
            message: "Unable to create user. Check that all values are valid.",
            type: "warning",
          })
        }
      })
    },
    submitUserEdit() {
      http.put(`/user/update/${encodeURIComponent(this.new_user.original_username)}`,{},{
        params: {
          new_username: this.new_user.username,
          full_name: this.new_user.full_name,
          title: this.new_user.title,
          email: this.new_user.email,
          phone: this.new_user.phone,
          is_admin: this.new_user.is_admin,
          password: this.new_user.password !== "" ? this.new_user.password : null,
        }
      })
      .then(() => {
        http.put('/tag/subscribe',{
          username: this.new_user.username,
          tags: this.new_user.subscriptions,
        })
        .then(() => {
          this.$emit('sync-users');
          this.editing_user = false;
          this.create_user = false;
          this.new_user = {
            original_username: null,
            username: '',
            password: '',
            full_name: '',
            title: '',
            email: '',
            is_admin: false,
          };
        })
        .catch((err) => {
          http.std_error_handler(this, err);
        })
      })
      .catch((err) => {
        if(http.std_error_handler(this, err)) {
          this.$emit('show-alert', {
            message: "Unable to update user. Check that all values are valid.",
            type: "warning",
          })
        }
      });
    },
    createTag() {
      http.put('tag/',
          {id: this.new_tag.id,
                description: this.new_tag.description,
                color: this.new_tag.color})
      .then(() => {
        this.$emit('sync-tags', this);
        this.create_tag = false;
        this.new_tag.id = ''
        this.new_tag.description = ''
        this.new_tag.color = '#000000'
      })
      .catch((err) => {
        if (http.std_error_handler(this, err)) {
          this.$emit('show-alert', {data: 'Failed to create new tag', type: 'warning'})
        }
      })
    },
    deleteTag(tag){
      http.delete('/tag/'+encodeURIComponent(tag.id))
      .then(() => {
        this.$emit('sync-tags');
      })
      .catch((err) => {
        http.std_error_handler(this, err);
      });
    },
    startTagEdit(tag) {
      if (tag === null) {
        this.new_tag = {
          id: '',
          description: '',
          color: '#000000',
        }
        this.editing_tag = false;
      }
      else {
        this.new_tag = {
          id: tag.id,
          description: tag.description,
          color: tag.color,
        }
        this.editing_tag = true;
      }
      this.create_tag = true;
    },
    randomColor() {
      const letters = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      this.new_tag.color = color;
    },
    startCostObjectEdit(co) {
      console.log(co);
      this.editing_cost_object = true;
      if (co === null) {
        this.new_cost_object.original_id = null;
        this.new_cost_object.id = '';
        this.new_cost_object.description = '';
        this.new_cost_object.active = true;
      } else {
        this.new_cost_object.original_id = co.id;
        this.new_cost_object.id = co.id;
        this.new_cost_object.description = co.description;
        this.new_cost_object.active = co.active;
      }
    },
    createCostObject() {
      http.put('requisition/cost-object',{},{
        params: {
          id_: this.new_cost_object.id,
          original_id: this.new_cost_object.original_id,
          description: this.new_cost_object.description,
          active: this.new_cost_object.active,
        }
      })
      .then(() => {
        this.$emit('sync-cost-objects');
        this.editing_cost_object = false;
      })
      .catch((err) => {
        http.std_error_handler(this, err);
      });
    }
  },
  beforeMount() {
    if (!this.loggedIn) {
      this.$router.push('/login');
    }
  },
}
</script>

<style scoped>

</style>